import { useEffect, useState } from "react";
import { Divider, Link, Typography } from "@mui/material";
import { AuthLogic, BaseIcon, TestableComponent } from "blace-frontend-library";
import { Link as RouterLink } from "react-router-dom";
import { ENV } from "@/src/const";
import { useAuth } from "@/src/hook";
import { BlaceV1Type } from "@/src/type";
import styles from "@/src/component/partial/Header/Header.module.scss";

export function Account() {
  const auth = useAuth();
  const [currentUser, setCurrentUser] = useState<BlaceV1Type.AuthType.LoginResponseProfile | undefined>(undefined);

  /**
   * current user info
   */
  useEffect(() => {
    (async () => {
        const currentUser: BlaceV1Type.AuthType.LoginResponseProfile = await AuthLogic.getAuthCurrentUser();
        setCurrentUser(currentUser);
    })();
  }, []);

  return (
    <div className={styles.accountWrapper}>
      <Typography component="h3" className={styles.title}>
        Account
      </Typography>
      <Link
        href={`${ENV.ROOT_DOMAIN_URL}/a/dashboard/profile`}
        className={styles.accountLink}
        aria-label="My Account"
        id={"b2bAccount.myAccount"}
      >
        <div className={styles.accountLinkContent}>
          <BaseIcon
            iconFileName="profileIcon"
            iconAlt="profile icon"
            className={styles.arrowOffsetRight}
          />
          Profile
        </div>
      </Link>
      <Link
        href={`${ENV.ROOT_DOMAIN_URL}/a/dashboard`}
        className={styles.accountLink}
        aria-label="Old Dashboard"
        id={"b2bAccount.oldDashboard"}
      >
        <div className={styles.accountLinkContent}>
          <BaseIcon
            iconFileName="eventsIcon"
            iconAlt="events icon"
            className={styles.arrowOffsetRight}
          />
          Old Dashboard
        </div>
      </Link>
      <TestableComponent currentUser={currentUser}>
        <RouterLink
          to="/listings"
          className={styles.accountLink}
          aria-label="Manage Listings"
          id={"b2bAccount.manageListings"}
        >
          <div className={styles.accountLinkContent}>
            <BaseIcon
              iconFileName="layers"
              iconAlt="layers icon"
              className={styles.arrowOffsetRight}
            />
            Manage Listings
          </div>
        </RouterLink>
      </TestableComponent>
      <Link
        onClick={() => auth.logout()}
        className={styles.accountLink}
        aria-label="Log Out"
        id={"b2bAccount.logOut"}
      >
        <div className={styles.accountLinkContent}>
          <BaseIcon
            iconFileName="logOutIcon"
            iconAlt="log out icon"
            className={styles.arrowOffsetRight}
          />
          Log out
        </div>
      </Link>
      <Divider className={styles.drawerDivider} />
    </div>
  );
}
